<template>
  <div>
    <div class="main">
      <div class="head">
        <div class="body">
          <div class="imgss">
<!--            <img class="title" src="@/assets/pc_appLogo.png" alt="">-->
            <div class="title">预约到店</div>
          </div>
          <div class="body-main">
            <div class="item sel">
              <el-select v-model="shopId" placeholder="" @change="getShopId" :popper-append-to-body="false">
                <el-option v-for="item in shopArr" :key="item.shopId" :value="item.shopId" :label="item.shopName"></el-option>
              </el-select>
            </div>
            <div class="item date">
              <label>到店时间</label>
              <el-date-picker type="date" v-model="dateval" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
              <el-select v-model="timeval" placeholder="" :popper-append-to-body="false">
                <el-option :value="item" v-for="(item, index) in timeArr" :key="index">{{item}}</el-option>
              </el-select>
            </div>
            <div class="item input">
              <label>真实姓名</label>
              <input type="text" placeholder="请输入姓名" ref="name">
            </div>
            <div class="item input">
              <label>手机号</label>
              <input type="text" placeholder="请输入手机号" ref="phone">
            </div>
            <div class="btn" @click="getMark">立即预约</div>
          </div>
        </div>
        <img class="body-bg" src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/pc_appbg.png" alt="">
      </div>
      <div class="apptitle">
        <div class="clesss">轻松预约体验</div>
        <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/pc_apptitle.png" alt="">
      </div>
    </div>
    <div class="foot">
      <div class="body">
        <div class="item">
          <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/pc_appone.png" alt="">
          <div class="title">专家服务</div>
          <div class="text">钻石专家一对一服务</div>
        </div>
        <div class="item">
          <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/pc_apptwo.png" alt="">
          <div class="title">进店试戴</div>
          <div class="text">海量闪耀钻戒,轻松试戴</div>
        </div>
        <div class="item">
          <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/pc_appthree.png" alt="">
          <div class="title">轻松试戴</div>
          <div class="text">海量闪耀钻饰,轻松试戴</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Appointment from '@/api/appointment'
  import Shop from '@/api/shop'

  // 获取时间
  function getDate() {
    const date = new Date();

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    month = month > 9 ? month : '0' + month;;
    day = day > 9 ? day : '0' + day;

    return `${year}-${month}-${day}`;
  }

  export default {
    name: 'appointment',
    data () {
      return {
        shopName: '',
        shopId: '',
        shopArr: '',
        // 日期
        dateval: getDate(),
        // 时间范围
        timeval: '09:00 - 10:00',
        // 时间范围数组
        timeArr: [
          '09:00 - 10:00',
          '10:00 - 11:00',
          '11:00 - 12:00',
          '12:00 - 13:00',
          '13:00 - 14:00',
          '14:00 - 15:00',
          '15:00 - 16:00',
          '16:00 - 17:00',
          '17:00 - 18:00',
          '18:00 - 19:00',
          '19:00 - 20:00',
          '20:00 - 21:00',
          '21:00 - 22:00',
          '22:00 - 23:00',
        ],
        // 商户id
        relationId: ''
      }
    },
    computed: {
      shopInit: function () {
        let shop = this.$store.state.shop
        let relationId = ''
        if (shop && this.$store.state.SequenceNo) {
          relationId = shop.relationId
        }
        return relationId
      }
    },
    watch: {
      shopInit: {
        handler (newValue, oldValue) {
          if (this.relationId != '' || newValue == '') return
          this.relationId = newValue
          if (newValue != '') {
            let shop = this.$store.state.shop
            this.$getif(shop.vipType)
            this.getInit()
          }
        },
        immediate: true,
      }
    },
    methods: {
      getInit() {
        let params = {
          RelationId: this.relationId
        }
        Shop.getshop(params).then(res => {
          console.log(res)
          if (res.status == 200) {
            this.shopName = res.result[0].shopName
            this.shopId = res.result[0].shopId
            this.shopArr = res.result
          }
        })
      },
      getShopId(id) {
        console.log(id)
        let name = ''
          this.shopArr.map((item, index) => {
          if (item.shopId == id) {
            name = item.shopName
          }
        })
        this.shopName = name
      },
      getMark() {

        let date = this.dateval
        let time = this.timeval.split(' - ')
        let name = this.$refs.name.value
        let phone = this.$refs.phone.value
        if(name == '') {
          this.$message({ type: 'error', message: '请输入姓名'});
          return
        }

        if(!RegExp(/^1[34578]\d{9}$/).test(phone)) {
          this.$message({ type: 'error', message: '请输入正确的手机号'});
          return
        }

        let params = {
          arriveTime: date+' '+time[0]+':00',
          arriveEndTime: date+' '+time[1]+':00',
          relationId: this.relationId,
          shopId: this.shopId,
          shopName: this.shopName,
          name: name,
          telephone: phone,
        }

        console.log(params)

        Appointment.add(params).then(res => {
          console.log(res)
          if(res.status == 200) {
            this.$router.push({
              path: '/success'
            })
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">

  .main {
    padding: 60px 0 0;

    .head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333333;

      .body {
        width: 505px;
        height: 400px;
        background: #F7DED8;
        padding: 0 30px 36px;
        /*text-align: center;*/

        .imgss{
          width: 100%;
          text-align: center;
          .title {
            font-size: 26px;
            color: #333333;
            font-family: SimSun;
            margin: 30px auto;
          }
        }
        .body-main {
          .item {
            margin-bottom: 15px;
            background: #FFFFFF;
            height: 42px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            label {
              min-width: 90px;
              padding: 0 20px;
            }

            &.input input {
              width: 100%;
              border: 0px;
              height: 100%;
              line-height: 42px;
            }
          }

          .btn {
            width: 100%;
            height: 42px;
            line-height: 42px;
            text-align: center;
            background: #FF8178;
            color: #FFFFFF;
          }
        }
      }

      /*穿透*/
      .sel {
        ::v-deep .el-select {
          width: 100%;
        }

        ::v-deep .el-input__inner {
          height: 42px;
          width: 100%;
          padding-left: 20px !important;
          border-color: transparent;
          border-radius: 0;
        }

        ::v-deep .el-input__icon {
          line-height: 42px !important;
        }
      }

      .date {
        ::v-deep .el-input__inner {
          height: 42px;
          width: 90px;
          padding: 0px !important;
          border-color: transparent;
          border-radius: 0;
        }

        ::v-deep .el-date-editor.el-input.el-input--prefix.el-input--suffix.el-date-editor--date {
          width: 90px;
        }

        ::v-deep .el-icon-arrow-up:before {
          content: '' !important;
        }
      }

      ::v-deep .el-select-dropdown__item.selected {
        color: #F5B5A9 !important;
      }

      ::v-deep .el-icon-date:before {
        content: '';
      }

      ::v-deep .el-icon-circle-close:before {
        content: '';
      }

      ::v-deep .el-input__icon.el-icon-circle-close {
        display: none !important;
      }

      .body-bg {
        width: 666px;
        height: 400px;
      }
    }

    .apptitle {
      width: 100%;
      margin: 61px auto 63px;
      text-align: center;
      position: relative;

      .clesss{
        width: 150px;
        height: 32px;
        background: #fff;
        position: absolute;
        top: 0;
        left: calc(50% - 70px);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 23px;
        font-family: SimSun;
        /*font-weight: bold;*/
      }

      img {
        width: calc(100% - 46px);
      }
    }
  }

  .foot {
    width: 100%;
    background: #FAFAFA;

    .body {
      width: 1200px;
      margin: 0 auto 80px;
      background: #FAFAFA;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      padding: 38px 0;

      .item {
        width: 33.3333%;
        text-align: center;

        img {
          width: 96px;
          height: 96px;
        }

        .title {
          font-size: 18px;
          font-weight: bold;
          color: #EE8976;
          margin: 15px 0 8px;
          font-family: SimSun;
        }

        .text {
          font-size: 14px;
          color: #4C4C4C;
        }
      }
    }
  }
</style>
<style lang="scss">
  .el-date-table td.available:hover {
    color: #F5B5A9;
  }

  .el-date-table td.current:not(.disabled) span {
    background: #F5B5A9 !important;
  }

  .el-date-table td.today span{
    color: #F5B5A9;
  }

  .el-message.el-message--info.successsel{
    background: #FF8178;
    color: #FFFFFF;

    .el-message__icon.el-icon-info{
      display: none;
    }

    .el-message__content{
      color: #FFFFFF;
    }
  }
</style>
